<template>
    <div class="app_nav">
        <ul class="app_nav__list">
            <li class="app_nav__item">
                <router-link to="/" class="app_nav__link">
                    <logo/>
                </router-link>
            </li>
            <!-- <li class="app_nav__item">
                <router-link to="/" class="app_nav__link">
                    <fa :icon="['fal', 'gauge-high']" />
                </router-link>
            </li> -->
            <li class="app_nav__item counter-number">
                <router-link to="/inbox" class="app_nav__link" @click.native="setHeaderButtonName('Message')" >
                        <span class="relative">
                            <fa :icon="['fal', 'inbox']" />
                            <span class="topbar_notification__float">
                                4
                            </span>
                        </span>
                        <p class="text-[10px] font-medium text-gray-300 hidden xs:!block leading-none">Inbox</p>
                </router-link>
            </li>
            <li class="app_nav__item">
                <!-- <router-link to="/timeline" class="app_nav__link">
                    <fa :icon="['fal', 'timeline']" />
                </router-link> -->
                <router-link to="/dashboard" class="app_nav__link">
                        <span class="relative">
                            <fa :icon="['fal', 'ticket']" />
                        </span>
                        <p class="text-[10px] font-medium text-gray-300 hidden xs:!block leading-none">Tickets</p>
                </router-link>
            </li> 
       
            <li class="app_nav__item counter-number">
                <router-link 
                    :to="{
                        path: '/diary',
                        query: {
                            date: dateToday || '',
                            id: '' || ''
                        }
                    }"
                    class="app_nav__link" @click.native="setHeaderButtonName('Event')"
                >
                <div class="icon-with-counter relative">
                    <span class="relative">
                        <fa :icon="['fal', 'calendar']" />
                        <span class="topbar_notification__float">
                                4
                        </span>
                    </span>
                    <p class="text-[10px] font-medium text-gray-300 hidden xs:!block leading-none">Diary</p>
                </div>
                </router-link>
            </li>
            <!-- <li class="app_nav__item">
                <router-link to="/dashboard" class="app_nav__link">
                    <fa :icon="['fal', 'gauge']" />
                </router-link>
            </li> -->
            <li class="app_nav__item">
                <router-link to="/contact-boards" class="app_nav__link" @click.native="setHeaderButtonName('Board')">
                    <span class="relative">
                        <fa :icon="['fal', 'circle-user']" />
                    </span>
                    <p class="text-[10px] font-medium text-gray-300 hidden xs:!block leading-none">Contacts</p>
              
                </router-link>
            </li>
            <li class="app_nav__item">
                <router-link to="/property-boards" class="app_nav__link" @click.native="setHeaderButtonName('Board')">
                    <span class="relative">
                        <fa :icon="['fal', 'home']" />
                    </span>
                <p class="text-[10px] font-medium text-gray-300 hidden xs:!block leading-none">Properties</p>
                </router-link>
            </li>
            <li class="app_nav__item xs:hidden">
                <router-link to="/accounts" class="app_nav__link" @click.native="setHeaderButtonName('Transaction')" >
                    <span class="relative">
                    <fa :icon="['fal', 'calculator-simple']" />
                </span>
                <p class="text-[10px] font-medium text-gray-300 hidden xs:!block leading-none">Dashboard</p>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useHeaderStore } from "@/stores/headerStore.js";
import logo from "@/assets/images/logo.vue";
// Vue router imports
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const headerStore = useHeaderStore();
const currentDate = ref(new Date());
const dateToday = ref('');
// const route = useRoute();

const setHeaderButtonName = async (name) => {
      headerStore.setHeaderButtonName(name);
      headerStore.setHeaderRouteName(router.currentRoute.value.name);
      headerStore.setBoardFormName('');
    };


onMounted(()=>{
    const day = currentDate.value.getDate().toString().padStart(2, '0');
    const month = (currentDate.value.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.value.getFullYear();
    dateToday.value = `${year}-${month}-${day}`;
    // console.log(dateToday, 'dateToday')
})
</script>

<style scoped>
.app_nav {
    @apply bg-white xs:bg-gray-100 dark:bg-dm-whitebg sm:h-screen dark:border-black-900 sm:w-16 fixed sm:left-0 sm:rtl:right-0 sm:top-0 w-screen h-14 xs:h-20 bottom-0 left-0 right-0 px-8 xs:px-2 sm:px-0 sm:shadow-none z-50;
}

.app_nav__list {
    @apply flex sm:flex-col items-center h-full text-2xl text-gray-200 dark:text-gray-darknavtext justify-between sm:justify-start;
}
.app_nav__item{
    @apply sm:max-h-[56px];
}
.app_nav__item.counter-number{
    @apply sm:max-h-[63px];
}
.app_nav__item.counter-number .app_nav__link{
    @apply sm:pt-[39px] text-gray-200 hover:text-black-900;
}
.app_nav__item:first-of-type {
    @apply hidden sm:inline-flex max-h-[none];
}

.app_nav__item:first-of-type .app_nav__link {
    @apply text-gray-darknavtext dark:text-black-900 hover:bg-gray-200 dark:hover:bg-dark-100 hover:rounded-[0.875rem] hover:rounded-tr-none p-[0.4375rem] pt-3 pb-[5px] mb-[-5px] dark:hover:text-black-900;
}

/* .app_nav__item:first-of-type .app_nav__link svg path {
    @apply fill-current;
} */


.app_nav__item:first-of-type .app_nav__link.router-link-active {
    @apply bg-gray-200 dark:bg-dark-100 dark:text-black-900;
    border-radius: 0.875rem;
    border-top-right-radius: 0;
}

/* .app_nav__item:nth-of-type(2) {
    @apply inline-flex md:hidden;
} */

.app_nav__link {
    @apply sm:py-8 md:pb-0 xs:py-4 xs:pb-6 md:px-3 inline-block hover:text-black-900 transition-all py-3 px-3 dark:text-gray-500 dark:hover:text-dm-white text-center xs:min-w-[71.8px] xs:px-2;
}
@media screen and (max-width:374px){
    .app_nav__link{
        @apply min-w-[50px] px-2;
    }
}


.router-link-active {
    @apply !text-black-900 xs:!text-black-500 dark:text-white;
}


.topbar_notification__float {
    @apply w-4 h-4 rounded-full flex items-start justify-center bg-red-500 text-white absolute -top-[0.3rem] -right-[0.5rem] rtl:-left-1.5 text-xs;
}
</style>
