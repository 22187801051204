// Auth Routes
export default [
    {
        path: '/',
        name: 'landing-page',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/AppLandingPage.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/signup',
        name: 'signup',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/SignupView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/LoginView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/create-password',
        name: 'create-password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/CreatePasswordView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/acquainted',
        name: 'acquainted',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/AcquaintedView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/workspace',
        name: 'workspace',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/WorkspaceView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/create-workspace',
        name: 'create-workspace',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/CreateWorkspaceView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/confirmation',
        name: 'confirmation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/ConfirmationView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/agency',
        name: 'agency',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/AgencyView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/invite-user',
        name: 'invite-user',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/InviteUserView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/set-profile',
        name: 'set-profile',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/SetProfileView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/reset-password-email',
        name: 'reset-password-email',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/ResetPasswordEmailView.vue'),
        meta: { isAuth: false },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/auth/ResetPasswordView.vue'),
        meta: { isAuth: false },
    }
];
