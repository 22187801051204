import { createInput } from '@formkit/vue';
import { ref } from 'vue';
const leftAngle = ref(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M203.9 405.3c5.877 6.594 5.361 16.69-1.188 22.62c-6.562 5.906-16.69 5.375-22.59-1.188L36.1 266.7c-5.469-6.125-5.469-15.31 0-21.44l144-159.1c5.906-6.562 16.03-7.094 22.59-1.188c6.918 6.271 6.783 16.39 1.188 22.62L69.53 256L203.9 405.3z"/></svg>`);
const rightAngle = ref('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"/></svg>');
/**
 * Here we define our input schema. We'll be using
 * createInput() so we're supplying the schema for
 * the "inner" section of a FormKit input.
 */
const numberInputSchema = [
    {
        $el: 'button',
        children: [
            {
                $el: 'span',
                attrs: {
                    innerHTML: leftAngle.value,
                },
            },
        ],
        attrs: {
            class: '$classes.stepInput',
            onClick: '$handlers.updateValue(-1)',
            type: 'button',
        },
    },
    {
        $el: 'input',
        bind: '$attrs',
        attrs: {
            id: '$id',
            name: '$name',
            type: 'number',
            class: '$classes.theme',
            onInput: '$handlers.DOMInput',
            onBlur: '$handlers.blur',
            disabled: '$disabled',
            value: '$_value',
        },
    },
    {
        $el: 'button',
        children: [
            {
                $el: 'span',
                attrs: {
                    innerHTML: rightAngle.value,
                },
            },
        ],
        attrs: {
            class: '$classes.stepInput',
            onClick: '$handlers.updateValue(1)',
            type:'button'
        }
    }
]

/**
 * Here we add our custom handlers to support
 * the unique needs of our input.
 */
function addHandlers(node) {
    node.on('created', () => {
        node.context.handlers.updateValue = (n) => () => {
            if (!node.context.disabled) {
                // step the value
                const value = parseInt(node.value) || 0;
                node.input(value + n);
                // trigger the blur handler because we've touched the input
                node.on('settled', () => {
                    setTimeout(() => {
                        // ensure we run after validation
                        node.context.handlers.blur();
                    }, 0);
                });
            }
        };
    });
}

/**
 * Export the output of the createInput call using
 * our schema and handlers function.
 */
export default createInput(numberInputSchema, {
    features: [addHandlers],
});
