export default [
    {
        path: '/property-boards',
        name: 'property-boards',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/properties/PropertyBoardView.vue'),
        meta: { isWorkspace: true },
    },
    {
        path: '/property-board/:id',
        name: 'property-board-details',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/properties/PropertyBoardDetailsView.vue'),
        meta: { isWorkspace: true },
    },
    {
        path: '/property-board/:id/detail/:propertyId',
        name: 'property.board.detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/properties/PropertyCardDetail.vue'),
        meta: { isWorkspace: true },
    },
];
