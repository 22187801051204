import { computed, ref, reactive, nextTick } from "vue";
import { defineStore } from "pinia";

export const useHeaderStore = defineStore("HeaderStore", () => {

        const header = ref({
            buttonName: null,
            GridOrListView: 'gridView',
            threeDotsButton: null,
            labelsList: null,
            routeName: null,
            boardStatus: null,
            grouplabels: [],
            timelineLabels: [],
            formName: null,
            userName: [],
            boardCount: 0,
            selectedItemStatus: false,
        });

        // Get active header
        const getAllHeaders = computed(() => header.value);

        // Get active buttonName
        const getHeaderBtnName = computed(() => header.value.buttonName);

        // Get header GridOrListView
        const getHeaderGridOrListView = computed(() => header.value.GridOrListView);

        // Get header threeDotsButton
        const getHeaderThreeDotsButton = computed(() => header.value.threeDotsButton);

        // Get header labelsList
        const getHeaderLabelsList = computed(() => header.value.labelsList);

        // Get header routeName
        const getHeaderRouteName = computed(() => header.value.routeName);

        // Get header boardStatus
        const getHeaderboardStatus = computed(() => header.value.boardStatus);

        // Get header grouplabels
        const getHeadergrouplabels = computed(() => header.value.grouplabels);

        // Get header timelineLabels
        const getHeadertimelineLabels = computed(() => header.value.timelineLabels);

        // Set header formName
        const getBoardFormName = computed(() => header.value.formName);

        // Set header getUserName
        const getUserName = computed(() => header.value.userName);

        // Set header getBoardCount
        const getBoardCount = computed(() => header.value.boardCount);

         // Set header getSelectedItemStatus
         const getSelectedItemStatus = computed(() => header.value.selectedItemStatus);

        // Set header buttonName
        const setHeaderButtonName = async (value) => {
            await nextTick();
            header.value.buttonName = value;
        };

        // Set header setSelectedItemStatus
        const setSelectedItemStatus = async (value) => {
            await nextTick();
            header.value.selectedItemStatus = value;
        };

        // Set header GridOrListView
        const setHeaderGridOrListView = (value) => {
            header.value.GridOrListView = value;
        };

        // Set header threeDotsButton
        const setHeaderthreeDotsButton = (value) => {
            header.value.threeDotsButton = value;
        };

        // Set header labelsList
        const setHeaderlabelsList = (value) => {
            header.value.labelsList = value;
        };

        // Set header routeName
        const setHeaderRouteName = (value) => {
            if (typeof header === 'object') {
                header.value.routeName = value;
            }
        };

        // Set header boardStatus
        const setHeaderboardStatus = async (value) => {
            await nextTick();
            header.value.boardStatus = value;
        };

        // Set header buttonName
        const setHeadergrouplabels = (value) => {
            header.value.grouplabels = value;
        };

        // Set header buttonName
        const setHeadertimelineLabels = (value) => {
            header.value.timelineLabels = value;
        };

         // Set header buttonName
         const setBoardFormName = async (value) => {
             await nextTick();
             header.value.formName = value;
        };

        // Set header buttonName
        const setUserName = (value) => {
            header.value.userName = value;
       };

       const setBoardCount = (value) => {
        header.value.boardCount = value;
       };
        

        return {
            header,
            getAllHeaders,
            getHeaderBtnName,
            getHeaderGridOrListView,
            getHeaderThreeDotsButton,
            getHeaderLabelsList,
            getHeaderRouteName,
            getHeaderboardStatus,
            getHeadergrouplabels,
            getHeadertimelineLabels,
            getBoardFormName,
            getUserName,
            getBoardCount,
            getSelectedItemStatus,
            setSelectedItemStatus,
            setHeaderboardStatus,
            setHeaderlabelsList,
            setHeaderButtonName,
            setHeaderGridOrListView,
            setHeaderthreeDotsButton,
            setHeaderRouteName,
            setHeadergrouplabels,
            setHeadertimelineLabels,
            setBoardFormName,
            setUserName,
            setBoardCount,
        };
    },
    {
        persist: true,
    });
