import { reactive } from "vue";

const toastsSuccess = reactive([]);
const toastsWarning = reactive([]);
const toastsError = reactive([]);
const toastsInfo = reactive([]);
const toastsGeneric = reactive([]);

export function Toast() {
    const showToast = (type, title, message) => {
        if (type === "Success") {
            toastsSuccess.push({ show: false, title: "", message: "" });
            activeToast(toastsSuccess, title, message);
        } else if (type === "Warning") {
            toastsWarning.push({ show: false, title: "", message: "" });
            activeToast(toastsWarning, title, message);
        } else if (type === "Error") {
            toastsError.push({ show: false, title: "", message: "" });
            activeToast(toastsError, title, message);
        } else if (type === "Info") {
            toastsInfo.push({ show: false, title: "", message: "" });
            activeToast(toastsInfo, title, message);
        } else if (type === "Generic") {
            toastsGeneric.push({ show: false, title: "", message: "" });
            activeToast(toastsGeneric, title, message);
        }
    };

    const activeToast = (t, title, message) => {
        t[t.length - 1].show = true;
        t[t.length - 1].title = title;
        t[t.length - 1].message = message;
        setTimeout(() => {
            t[t.length - 1].show = false;
            t.pop();
        }, 5000);
    };

    return { toastsSuccess, toastsWarning, toastsError, toastsInfo, toastsGeneric, showToast };
}
