import {ref} from 'vue'

const spinnerModal = ref('')
const LoaderContent = ref('');
export function SpinnerLoader (){
    const showLoader = (content)=>{
        spinnerModal.value.showFormModal('spinnerModalToShow');
        LoaderContent.value = content
    }
   const closeLoader =()=>{
    spinnerModal.value.closeFormModal('spinnerModalToShow')
   }

return {
    spinnerModal,
    LoaderContent,
    showLoader,
    closeLoader
}
}