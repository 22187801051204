// Imports
import axios from "axios";
import { Toast } from "@/composables/Toast";
import { useTenantStore } from "@/stores/tenant";

const { showToast } = Toast();
axios.defaults.withCredentials = true;

// Create Axios Instance
const AxiosAuth = axios.create({
    baseURL: `${import.meta.env.VITE_APP_AUTH_BASE_URL}/api/v1`
});

// Add Tenant ID to Every Request
const addHeader = async (config) => {
    const tenantStore = useTenantStore();
    const tenantId = tenantStore.getTenantId;
    if (tenantId) {
        config.headers["X-Tenant"] = tenantId;
    }
};

AxiosAuth.interceptors.request.use(
    (config) => {
        return new Promise(async (resolve) => {
            // Modify the request config here
            await addHeader(config);
            resolve(config);
        });
    },
    (error) => {
        console.error("Request Interceptor Error:", error);
        return Promise.reject(error);
    }
);

AxiosAuth.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            // localStorage.clear();
            // window.location.href = import.meta.env.VITE_APP_DOMAIN;
        } else if (
            error.response.status === 500 ||
            error.response.status >= 500
        ) {
            showToast("Error", "An internal server error occurred");
        } else {
            // showToast('Error', error.response.data.message);
        }
        return Promise.reject(error);
    }
);

export default AxiosAuth;
