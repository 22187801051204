<template>
    <div ref="modalBackdropRef" class="modal_backdrop" @click="!persist ? toggleModal(): true , onHideModal()"></div>
</template>

<script setup>
import useToggleModal from "@/composables/useToggleModal";
import { onMounted } from 'vue';
import { useHeaderStore } from "@/stores/headerStore.js";
const emit = defineEmits(["onHideModal"]);
const headerStore = useHeaderStore();

defineProps({
    persist: {
        type: Boolean,
        default: false,
    },
});
const { toggleModal } = useToggleModal();

const onHideModal = () => {
    headerStore.setBoardFormName('');
    emit("onHideModal");
};
onMounted(()=>{
    // modalBackdropRef.value.style = 'pointer-events:none'
})
</script>

<style scoped>
.modal_backdrop {
    @apply bg-dark-200 dark:bg-black-900 dark:bg-opacity-50 bg-opacity-10 fixed left-0 top-0 h-screen w-screen z-10 transition-all;
}
</style>
