// Imports
import axios from 'axios';
import { useTenantStore } from '@/stores/tenant';
import { Toast } from '@/composables/Toast';

const { showToast } = Toast();

axios.defaults.withCredentials = true;

// Create Axios Instance
const Axios = axios.create({
    baseURL: `${import.meta.env.VITE_APP_BASE_URL}/api/v1`,
});

// Add Tenant ID to Every Request
const addHeader = async (config) => {
    const tenantStore = useTenantStore();
    const tenantId = tenantStore.getTenantId;
    if (tenantId) {
        config.headers['X-Tenant'] = tenantId;
    }
};

// Request Interceptor
Axios.interceptors.request.use(
    (config) => {
        addHeader(config);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
Axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === 401) {
            // localStorage.clear();
            // window.location = `${import.meta.env.VITE_APP_DOMAIN}/login`;
        } else if (status === 500 || status >= 500) {
            showToast('Error', 'An internal server error occurred');
        } else {
            // showToast('Error', error.response.data.message);
        }

        return Promise.reject(error);
    }
);
export default Axios;
