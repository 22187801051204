// Imports
import Axios from "@/config/AxiosConfig";
import AxiosAuth from "@/config/AxiosAuthConfig";

// Get Single Workspace
export const getWorkSpace = async (val) => {
    const identifier = val ?? window.location.hostname.split('.')[0];
    const url = `/user/workspace/${identifier}`;
    
    try {
        const { data } = await AxiosAuth.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};


// Get Users
export const updateWorkspaceSettings = async (payload) => {
    const url = "/setting/update-workspace-setting";

    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};
export const updateWorkspaceDomain = async (payload) => {
    const url = "/setting/update-workspace-domain";

    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const setWorkspaceTheme = async (data) => {
    const url = `/setting/change-appearance/${data.id}/${data.appearance}`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};
