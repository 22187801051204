<template>
    <!-- <transition name="slide" appear v-if="screenWidth <= mobileDisplay"> -->
        <div class="modal_content" ref="modalContentRef" :class="[transparentModalContent ? '!bg-transparent' : '']">
            <slot></slot>
        </div>
    <!-- </transition> -->
    <!-- <transition v-else name="fade" appear> -->
        <!-- <div class="modal_content" ref="modalContentRef" :class="[transparentModalContent ? '!bg-transparent' : '']">
            <slot></slot>
        </div> -->
    <!-- </transition> -->
</template>

<script setup>
import { useModalContent } from '@/composables/useModalContent.js';
import { onMounted, ref } from 'vue';
const screenWidth = window.innerWidth;
const props = defineProps({
    transparentModalContent: {
        type: Boolean,
        default: false,
    },
});
const mobileDisplay = ref('767');
const { modalContentRef } = useModalContent();
onMounted(() => {
})
</script>

<style scoped>
.modal_content {
    @apply relative flex flex-col justify-between w-full max-h-full pointer-events-auto bg-white dark:bg-dm-whitebg bg-clip-padding shadow-md sm:rounded-lg xs:rounded-t-2xl xs:shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] overflow-hidden ;
    z-index: 999; 
}

@keyframes fade {
    0% {
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    100% {
        opacity: 1;
        transition: opacity 0.5s ease;
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes slideOut {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.slide-enter-active {
    animation: slide-in 500ms;
}

.slide-leave-active {
    animation: slide-in 700ms reverse;
}
.modal_content :deep(.formkit-actions){
    display: none;
}

/* .slide-enter-from,
.slide-leave-to {
    opacity: 0;
} */
</style>
