// directives.js

export const focus = {
    // When the bound element is inserted into the DOM...
    mounted(el) {
        const input = el.querySelector('input');
    if (input) {
      input.focus();
    }
    }
  };
