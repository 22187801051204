<template>
  <div
    class="circular-progress flex text-center items-center py-4 pl-4 pr-4 rounded-lg justify-center"
    :class="[bgColor ? 'bg-dark-100 text-white' : '']"
  >
    <!-- Intermediate Spinner -->
    <div class="flex align-center justify-center mr-3">
      <!-- Add your intermediate spinner HTML here -->
      <!-- For example, you can use a loading animation or a spinner icon -->
      <div class="intermediate-spinner text-center">
        <!-- Replace with your SVG spinner code -->
        <fa
          class="text-2xl" style="color: #00B0FC;"
          :icon="['fal', 'spinner-third']" spin
      ></fa>
      </div>
    </div>

    <!-- Determinate Spinner -->
    <div v-if="determinate">
      <div>
        <svg class="circle w-6 h-6">
          <circle
            :r="radius"
            cx="50%"
            cy="50%"
            :stroke-width="strokeWidth"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="dashOffset"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div class="progress-label text-sm font-medium text-white w-10">{{ currentProgress }}%</div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';

const props = defineProps({
  bgColor: {
    type: Boolean,
    default: false,
  },
  determinate: {
    type: Boolean,
    default: false,
  },
});

const showIntermediateSpinner = ref(true); // Initially, show the intermediate spinner
const progress = ref(100);
const currentProgress = ref(0);
const radius = ref(10);
const strokeWidth = ref(1.7);
let switchToDeterminateTimeout; // Timeout for switching to determinate spinner

const circumference = computed(() => 2 * Math.PI * radius.value);
const dashOffset = computed(
  () => circumference.value * (1 - currentProgress.value / 100)
);

const startProgressTimer = () => {
  const interval = setInterval(() => {
    if (currentProgress.value < progress.value) {
      currentProgress.value += 1;
    } else {
      clearInterval(interval);
    }
  }, 60); // Adjust the interval duration as needed for smoother animation
};

const switchToDeterminateSpinner = () => {
  clearTimeout(switchToDeterminateTimeout); // Clear any existing timeout
  showIntermediateSpinner.value = false; // Hide intermediate spinner
  startProgressTimer(); // Start the determinate spinner
};

// Show intermediate spinner for 3 seconds (adjust the duration as needed)
setTimeout(() => {
  switchToDeterminateSpinner(); // Switch to determinate spinner after timeout
}, 5000); // Show intermediate spinner for 5 seconds (adjusted from 3 seconds)

onMounted(() => {
  // Set a timeout to switch to determinate spinner after the intermediate spinner animation
  switchToDeterminateTimeout = setTimeout(() => {
    switchToDeterminateSpinner();
  }, 5000); // Wait for 3 seconds (adjust as needed)
});
</script>

<style scoped>
/* ... Rest of the styles remain the same */
</style>


<style scoped>
.circular-progress {
    @apply relative;
    width: fit-content;
    margin: 0 auto;
}

.circle {
    fill: none;
    stroke: #00B0FC; /* Change this to your desired color */
    transition: stroke-dashoffset 0.3s;
    animation: progressAnimation 2s linear forwards;
}
.spinner {
    animation: rotate 2s linear infinite;
}
.spinner .path {
    animation: dash 1.5s ease-in-out infinite;
}
.circle_intermedaite {
    fill: none;
    stroke: #00B0FC; /* Change this to your desired color */
    transition: stroke-dashoffset 0.5s;
    animation: interAnimation 4s linear forwards infinite;
}
.progress-label {
    opacity: 0; /* Hide the label during animation */
    animation: labelAnimation 2s linear forwards; /* Add label animation */
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes progressAnimation {
    from {
        stroke-dashoffset: 1000; /* Adjust the initial offset value */
    }
    to {
        stroke-dashoffset: var(--dashOffset);
    }
}

@keyframes labelAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>
