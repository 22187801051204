import { computed, ref } from "vue";
import { defineStore } from "pinia";

export const useTenantStore = defineStore("tenantStore", () => {

        const tenant = ref({});

        // Get Tenant
        const getTenant = computed(() => tenant.value);

        // Get Tenant Id
        const getTenantId = computed(() => tenant.value.pivot?.tenant_id);

        const setTenant = (value) => {
            tenant.value = value;
        };

        return { tenant, getTenant, getTenantId, setTenant };
    },
    {
        persist: true
    });
