import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

export const useChatStore = defineStore(
    'chatStore',
    () => {
        const user_detail = ref({});
        const toggle_chat = ref(false)
        const selection = ref(false)
        return {
            user_detail,toggle_chat,selection
        };
    },

);
