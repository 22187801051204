import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

export const useBoardStore = defineStore(
    'boardStore',
    () => {
        const currentBoard = ref('Page Name');
        const currentBoardView = ref('gridView');
        const PropertyBoardView = ref('gridView');
        const diaryBoardView = ref('day');
        const contactBoard = ref({
            id: null,
            name: '',
            contactTypeId: null,
            contactTypeName: '',
            labels: [],
            steps: [],
            users: [],
            status: 0,
        });

        // Get contact board
        const getContactBoard = computed(() => contactBoard.value);
        const getCurrentBoard = computed(() => currentBoard.value);

        // Set contact board
        const setContactBoard = (value) => {
            contactBoard.value = value;
        };
        const setContactBoardLabels = (value) => {
            contactBoard.value.labels = value;
        };
        const setContactBoardView = (value) => {
            currentBoardView.value = value;
        };
        const setPropertyBoardView = (value) => {
            PropertyBoardView.value = value;
        };

        const setDiaryBoardView = (value) => {
            diaryBoardView.value = value;
        };

        const propertyBoard = ref({
            id: null,
            name: '',
            contactTypeId: null,
            contactTypeName: '',
            labels: [],
            steps: [],
        });

        const setCurrentBoard = (value) => {
            currentBoard.value = value;
        };
        // Get contact board
        const getPropertyBoard = computed(() => propertyBoard.value);

        // Set contact board
        const setPropertyBoard = (value) => {
            propertyBoard.value = value;
        };
        const setPropertyBoardLabels = (value) => {
            propertyBoard.value.labels = value;
        };

        return {
            currentBoard,
            currentBoardView,
            getCurrentBoard,
            setCurrentBoard,
            contactBoard,
            getContactBoard,
            setContactBoard,
            setContactBoardLabels,
            PropertyBoardView,
            propertyBoard,
            getPropertyBoard,
            setPropertyBoard,
            setPropertyBoardLabels,
            setContactBoardView,
            setPropertyBoardView,
            setDiaryBoardView,
            diaryBoardView
        };
    },
    {
        persist: true,
    }
);
