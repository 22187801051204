export default [
    {
        path: '/contact-boards',
        name: 'contact-boards',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/contacts/ContactBoardsView.vue'),
        meta: { isWorkspace: true },
    },
    {
        path: '/contact-board/:id',
        name: 'contact-board-details',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/contacts/ContactBoardDetailsView.vue'),
        meta: { isWorkspace: true },
    },
    {
        path: '/board/:id/detail/:groupId',
        name: 'contact.board.detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/contacts/BoardDetailView.vue'),
        meta: { isWorkspace: true },
    },
];
