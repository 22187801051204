export default [
    {
        path: '/accounts',
        name: 'accounts',
        redirect: {name:'client-account'} ,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/accounts/Account.vue'),
        meta: { isWorkspace: true },
        children: [
            {
                path: 'client_account',
                name: 'client-account',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/ClientAccount.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'deposit_account',
                name: 'deposit-account',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/DepositAccount.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'deposit_custodial',
                name: 'deposit-custodial',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/DepositCustodial.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'money_in',
                name: 'money-in',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/MoneyIn.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'money_out',
                name: 'money-out',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/MoneyOut.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'floats',
                name: 'floats',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/DepositsFloats.vue'),
                meta: { isWorkspace: true },
            },
           
        ],
    },
];
