<template>
<svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_10667_60762)">
                            <path
                                d="M36.6686 0H3.33141C1.49152 0 0 1.49152 0 3.33141V36.6686C0 38.5085 1.49152 40 3.33141 40H36.6686C38.5085 40 40 38.5085 40 36.6686V3.33141C40 1.49152 38.5085 0 36.6686 0Z"
                                fill="black"
                            />
                            <path
                                d="M6.67578 16.717H8.65157L10.27 20.8553H10.3622L11.9945 16.717H13.9565L11.1576 23.2161H9.44696L6.67578 16.717Z"
                                fill="white"
                            />
                            <path
                                d="M21.2132 21.5816L24.3348 18.2086H21.1256V16.7239L25.8749 16.7101C26.2322 16.7101 26.5227 16.9983 26.5227 17.3556V17.8375C26.5227 18.0196 26.4535 18.1971 26.329 18.3308L23.1544 21.7337H26.5227V23.2184H21.6651C21.3077 23.2184 21.0195 22.9303 21.0195 22.5729V22.0703C21.0195 21.8882 21.0887 21.713 21.2132 21.5792V21.5816Z"
                                fill="white"
                            />
                            <path
                                d="M31.6134 22.5384C31.6134 22.5384 31.0923 23.011 30.7719 23.1747C30.4722 23.3291 30.1148 23.4398 29.6652 23.4398C29.3286 23.4398 29.0128 23.3845 28.7177 23.2738C28.4226 23.1631 28.1621 23.011 27.9431 22.815C27.7217 22.6213 27.5488 22.3862 27.4266 22.1118C27.3021 21.8375 27.2422 21.5378 27.2422 21.2081C27.2422 20.8784 27.309 20.5695 27.4405 20.2997C27.5742 20.03 27.7586 19.7971 27.9984 19.6035C28.2382 19.4098 28.5171 19.26 28.8399 19.1585C29.1626 19.0571 29.5131 19.0064 29.8935 19.0064C30.3177 19.0064 30.6658 19.0386 30.9402 19.1055C31.2145 19.1724 31.4405 19.2415 31.6157 19.3107V19.1124C31.6157 18.7666 31.4797 18.483 31.2053 18.2571C30.9309 18.0311 30.5897 17.9182 30.184 17.9182C29.5822 17.9182 29.075 18.1695 28.6601 18.6744L27.399 17.8121C28.0883 16.9453 29.0312 16.5118 30.2232 16.5118C31.2307 16.5118 32.0007 16.7493 32.5309 17.2219C33.0612 17.6945 33.3263 18.3977 33.3263 19.3268V23.2945H31.6157V22.5407L31.6134 22.5384ZM31.6134 20.5879C31.4105 20.4911 31.2007 20.4127 30.984 20.3551C30.7673 20.2974 30.5298 20.2698 30.2739 20.2698C29.8589 20.2698 29.5384 20.3597 29.3125 20.5418C29.0866 20.7239 28.9736 20.9476 28.9736 21.2127C28.9736 21.4778 29.082 21.6899 29.2987 21.849C29.5154 22.0081 29.7644 22.0888 30.048 22.0888C30.2785 22.0888 30.4906 22.0473 30.6843 21.962C30.8779 21.879 31.0439 21.7683 31.1822 21.63C31.3206 21.4917 31.4243 21.3326 31.5004 21.1528C31.5765 20.9706 31.6134 20.7839 31.6134 20.5879Z"
                                fill="white"
                            />
                            <path
                                d="M17.2753 16.5648C15.3641 16.5648 13.8125 18.1141 13.8125 20.0276C13.8125 21.9412 15.3618 23.4905 17.2753 23.4905C19.1889 23.4905 20.7381 21.9412 20.7381 20.0276C20.7381 18.1141 19.1889 16.5648 17.2753 16.5648ZM17.2753 21.872C16.2563 21.872 15.4286 21.0467 15.4286 20.0253C15.4286 19.004 16.254 18.1787 17.2753 18.1787C18.2966 18.1787 19.122 19.004 19.122 20.0253C19.122 21.0467 18.2966 21.872 17.2753 21.872Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_10667_60762">
                                <rect width="40" height="40" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </template>
                <script setup></script>